
@font-face {
  font-family: Futura;
  src: url(./assets/fonts/futura/FuturaStd-Bold.woff2) format("woff2");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: Futura;
  src: url(./assets/fonts/futura/FuturaStd-Book.woff2) format("woff2");
  font-weight: 400;
  font-display: swap
}
@font-face {
  font-family: Futura;
  src: url(./assets/fonts/futura/FuturaStd-Book-Otf-Data.otf) format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "FuturaMedium";
  src: url("./assets/fonts/futura/FuturaStd-Medium.eot");
  src: url("./assets/fonts/futura/FuturaStd-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/futura/FuturaStd-Medium.svg#FuturaStd-Medium") format("svg"),
    url("./assets/fonts/futura/FuturaStd-Medium.ttf") format("truetype"),
    url("./assets/fonts/futura/FuturaStd-Medium.woff") format("woff"),
    url("./assets/fonts/futura/FuturaStd-Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "FuturaBold";
  src: url("./assets/fonts/futura/FuturaStd-Bold.eot");
  src: url("./assets/fonts/futura/FuturaStd-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/futura/FuturaStd-Bold.svg#FuturaStd-Bold") format("svg"),
    url("./assets/fonts/futura/FuturaStd-Bold.ttf") format("truetype"),
    url("./assets/fonts/futura/FuturaStd-Bold.woff") format("woff"),
    url("./assets/fonts/futura/FuturaStd-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
